<template>
  <div class="proposal-grid">
    <div class="proposal-title-wrapper" v-if="title">
      <h2
        v-html="title"
        class="proposal-title white--text secondary lighten-1 text-uppercase mb-3"
      ></h2>
    </div>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        v-for="proposal in proposals"
        :key="proposal.proposalId"
      >
        <ProposalImage :proposal="proposal" />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.proposal-grid {
  h3 {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 15px;
    // text-transform: uppercase;
    font-size: 30px !important;
  }
}
</style>
<script>
import ProposalImage from "./ProposalImage.vue";

export default {
  components: { ProposalImage },
  name: "ProposalGrid",
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true }
  }
};
</script>
